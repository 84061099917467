import Retool from 'react-retool';

function App() {
  return (
    <div class="app">
      <Retool url="https://primocare.retool.com/embedded/public/973074d9-bd8c-4f1d-ae83-10961b0d9dc8"/>
    </div>
  );
}

export default App;
